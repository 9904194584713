import React, { Component } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import AOS from "aos"
import 'aos/dist/aos.css';
import NewsStrip from "../../components/news-strip"

class NewsPage extends Component {

  componentDidMount(){
    AOS.init();
    window.addEventListener('load', AOS.refresh);
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000);
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Tetley's add wheelchair accessible coach to fleet" description="Tetley's Coaches continues to add to our private hire fleet and one of the latest is an executive class coach with disabled access/wheelchair lift." />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent page-article" style={{ background: 'white' }}>
            <div>
              <h1 className="page-content-heading">Tetley's add wheelchair accessible coach to fleet</h1>
              <p className="news-article-date">November 2012</p>
            </div>

            <div className="news-article-body">
              <p>Tetley's Coaches continues to add to our private hire fleet and one of the latest is an executive class coach with disabled access/wheelchair lift.</p>
              <p>The coach is a British built Plaxton with wheelchair lift to allow access for wheelchairs and physically impaired passengers.</p>
              <p>It is fully seat-belted, air conditioned and fitted with a rear floor-level toilet and washroom, providing a high standard of comfort for all passengers.</p>
              <p>The spacious interior can accommodate one wheelchair plus 47 seated passengers or alternatively 49 seated passengers.</p>
            </div>
          </div>
        </div>

        <NewsStrip />

      </Layout>
    );
  }
}

export default NewsPage
